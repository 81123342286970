/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://dg248qlyw3.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://dg248qlyw3.execute-api.eu-central-1.amazonaws.com/prod/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_PYPNoGJ1P",
    APP_CLIENT_ID: "6lvh702o3lfj6svtngab2vbdf8",
    IDENTITY_POOL_ID: "eu-central-1:1155b226-7fac-41a8-a148-1002f78bbb54",
  },
  i18n: {
    host: "locales.digital.beta.canei.io",
  },
  assets: {
    host: "assets.digital.beta.canei.io",
  },
  payment: {
    publicKey: "pk_test_51Kb2CFJafEP8n6QGsNJNLK9iljof2ilJpV8XPLHv9Q5nRP4vtaY8fvpevUs2wGAb83ZgiREDXqucNGsrm2HthSTf00Jy8h3kW8",
  },
  registration: {
    FORM_POST: "https://support.digital.beta.canei.io/v1/forms",
    NEXT_PAGE: "https://app.digital.beta.canei.io",
    TO_REFERRER: "https://canei.ag/register-success/",
  },
};

export default awsConfig;
